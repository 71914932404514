import { isJSON } from '~/utils/checkout.ts';
import axios from 'axios';
import { useCountryCode } from '~/stores/country';
import { useGlobalStore } from '~/stores/global';
import type { InboundMarketing } from '~/types/store';

class _FBConversion {
	apiWorker: Worker | null = null;

	async apiCall(payload: {
		event_name: string;
		content?: object;
		custom_data?: object;
	}) {
		const config = useRuntimeConfig();
		const userUUID = useCookie('user_uuid');
		const countryCode = useCountryCode().countryCode;
		const zipCode = useCookie('zip_code');

		const fbp = useCookie('_fbp');
		const fbc = useCookie('_fbc');

		const user = isJSON(localStorage.getItem('logan')!)
			? JSON.parse(localStorage.getItem('logan')!)
			: null;

		const { event_name, ...restPayload } = payload;
		const data = {
			event_data: {
				event_name: event_name,
				event_id: userUUID.value,
				action_source_url: window.location.href,
			},
			customer_data: {
				first_name: user && user.firstName ? user.firstName : '',
				last_name: user && user.lastName ? user.lastName : '',
				email: user && user.email ? user.email : '',
				country: countryCode ?? null,
				zipCode: zipCode.value ? zipCode.value.toString() : null,
				fbp: fbp.value ?? null,
				fbc: fbc.value ?? null,
			},
			...restPayload,
		};

		try {
			const GlobalStore = useGlobalStore();
			const inbound_marketing = GlobalStore.store
				.inbound_marketing as InboundMarketing;
			const metaPixelId = inbound_marketing?.meta_pixel?.meta_pixel_id ?? '';
			const metaAccessToken =
				inbound_marketing?.meta_pixel?.meta_access_token ?? '';
			const storeDomain =
				GlobalStore.storeType == 'OCTANE' ? GlobalStore.domain : '';
			const headers = {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'X-Store': storeDomain,
				'Meta-Pixel-Id': metaPixelId,
				'Meta-Access-Token': metaAccessToken,
			};
			const res = await axios.post(
				`${config.public.apiBaseUrl}/server_events/fb_conversions`,
				JSON.stringify(data),
				{
					headers: headers,
				},
			);
			if (res.status === 200) {
				return await res.data;
			} else {
				console.log('Fb conversion error');
				// throw new Error('Fb conversion error');
			}
		} catch (error) {
			console.log('Fb conversion error', error);
			// throw new Error('Fb conversion error');
		}
	}
}

export const FBConversion = new _FBConversion();
