import type { InjectionKey } from 'vue';

export type NewArrivalsPLPExperiment = {
	new_arrival_period: string;
	is_active: boolean;
};

export type NewArrivalsPageExperiment = {
	is_active: boolean;
	min_sellable_products: number;
	brand_created_at: string;
	drop_created_at: string;
};

export type NewArrivalsFilterExperiment = {
	is_active: boolean;
	period: string;
};

export type PDPRedesignExperiment = {
	showSizeForm: boolean;
};

export type HideExpressTogglePLPExperiment = {
	showExpressTag: boolean;
};

export type SearchButtonV2Experiment = {
	is_active: boolean;
};

export type CartShippingExperiment = {
	allowed: boolean;
};

export const newArrivalPLPExperimentInjectionKey = Symbol(
	'newArrivalPLPExperiment',
) as InjectionKey<Ref<NewArrivalsPLPExperiment | undefined>>;
export const deliveryMethodsExperimentInjectionKey = Symbol(
	'deliveryMethodsExperiment',
) as InjectionKey<Ref<boolean | undefined>>;
export const newArrivalPageExperimentInjectionKey = Symbol(
	'newArrivalPageExperiment',
) as InjectionKey<Ref<NewArrivalsPageExperiment | undefined>>;
export const newArrivalsFilterExperimentInjectionKey = Symbol(
	'newArrivalsFilterExperiment',
) as InjectionKey<Ref<NewArrivalsFilterExperiment | undefined>>;
export const PDPRedesignExperimentInjectionKey = Symbol(
	'PDPRedesignExperiment',
) as InjectionKey<Ref<PDPRedesignExperiment | undefined>>;

export const checkoutRedesignExperimentInjectionKey = Symbol(
	'checkoutRedesignExperiment',
) as InjectionKey<Ref<boolean | undefined>>;
export const searchButtonV2ExperimentInjectionKey = Symbol(
	'searchButtonV2Experiment',
) as InjectionKey<Ref<SearchButtonV2Experiment | undefined>>;
export const hideExpressTogglePLPExperimentInjectionKey = Symbol(
	'hideExpressTogglePLPExperiment',
) as InjectionKey<Ref<HideExpressTogglePLPExperiment | undefined>>;

export const CartShippingExperimentInjectionKey = Symbol(
	'cartShippingExperiment',
) as InjectionKey<Ref<CartShippingExperiment | undefined>>;

export const useStatsigExperiment = () => {
	const newArrivalPLPExperiment = inject(newArrivalPLPExperimentInjectionKey);
	const deliveryMethodsExperiment = inject(
		deliveryMethodsExperimentInjectionKey,
	);
	const newArrivalPageExperiment = inject(newArrivalPageExperimentInjectionKey);
	const newArrivalsFilterExperiment = inject(
		newArrivalsFilterExperimentInjectionKey,
	);
	const pdpRedesignExperiment = inject(PDPRedesignExperimentInjectionKey);
	const checkoutRedesignExperiment = inject(
		checkoutRedesignExperimentInjectionKey,
	);
	const searchButtonV2Experiment = inject(searchButtonV2ExperimentInjectionKey);
	const hideExpressTogglePLPExperiment = inject(
		hideExpressTogglePLPExperimentInjectionKey,
	);
	const cartShippingExperiment = inject(CartShippingExperimentInjectionKey);

	return {
		newArrivalPLPExperiment,
		deliveryMethodsExperiment,
		newArrivalPageExperiment,
		newArrivalsFilterExperiment,
		pdpRedesignExperiment,
		checkoutRedesignExperiment,
		searchButtonV2Experiment,
		hideExpressTogglePLPExperiment,
		cartShippingExperiment,
	};
};
