<script setup lang="ts">
import { ConfigProvider } from 'radix-vue';
import { useRoute } from 'vue-router';
import { useRuntimeConfig } from 'nuxt/app';
import { v4 as uuidv4 } from 'uuid';
import { FBConversion } from '~/utils/fbConversion.ts';
import { useGlobalStore } from './stores/global';
import seoSchema from '~/utils/seoSchema.ts';
import { useCountryCode } from './stores/country';
import { StatsigSingleton } from '@laam/statsig-client';
const useIdFunction = () => useId();
const { $eventClient, $cathodeClient } = useNuxtApp();
const route = useRoute();

const url = useRequestURL();

const GlobalStore = useGlobalStore();
const CountryCodeStore = useCountryCode();
const { setCountryCode } = CountryCodeStore;

const checkoutLocalization = ref<boolean>(true);

// add meta tags only on server
if (!process.client) {
	await GlobalStore.fetchStoreConfigAndGlobalSections();
	const headers = useRequestHeaders();
	GlobalStore.setDomain();

	const geoLocation = headers?.['x-client-geo-location'] ?? null;
	let countryCode = '';
	if (geoLocation) {
		countryCode = extractCountryCode(geoLocation)!;
	} else {
		countryCode = 'PK';
	}
	setCountryCode(countryCode);
}

// scroll state
const visible = ref(true);
const scrollPosition = ref(0);
const scrollBuffer = 72;

const toggleVisibility = () => {
	const currentScrollPos = document.documentElement.scrollTop;
	if (currentScrollPos - scrollPosition.value > scrollBuffer) {
		visible.value = false;
		scrollPosition.value = currentScrollPos;
	} else if (scrollPosition.value - currentScrollPos > scrollBuffer) {
		visible.value = true;
		scrollPosition.value = currentScrollPos;
	}
};

const testUrls = ['localhost', 'dev', 'test', 'shop', 'local', 'dev-v2'];
if (testUrls.some((testUrl) => url.host.includes(testUrl))) {
	useHead({
		meta: [
			{
				name: 'robots',
				content: 'noindex',
			},
		],
	});
}

const gsvTags = ['dev', 'test', 'shop', 'local', 'dev-v2', 'laam'];
if (gsvTags.some((gsvTag) => url.host.includes(gsvTag))) {
	useHead({
		meta: [
			{
				name: 'google-site-verification',
				content: 'WzMytRFwM90Jj60jTuyFKOFWuP8jUxuf1PGCbTou3tQ',
			},
		],
	});
}

const newFilterUi = ref(false);
const deliveryOptionsExp = ref(true);
const globalCutoffConfig = ref();
onMounted(() => {
	globalCutoffConfig.value = StatsigSingleton.getDynamicConfig(
		'shipping-cutoff-config',
	).value;

	newFilterUi.value = StatsigSingleton.getExperiment('reverse-filter-ui').get(
		'newFilterUi',
		false,
	);

	deliveryOptionsExp.value = StatsigSingleton.getExperiment(
		'deliver-to-reverse-exp',
	).get('showDeliverToOptions', true);

	window.addEventListener('scroll', toggleVisibility);
	$eventClient.sendEvent('session-start', {
		location: window.location.href,
		referrer: document.referrer,
	});

	$eventClient.sendEvent('page-view', {
		location: window.location.href,
		referrer: document.referrer,
	});
	logPagePerformance();
	setUserUUID();

	window.addEventListener('beforeunload', () => {
		$cathodeClient.fireAtWindowClose();
	});
});

onUnmounted(() => {
	window.removeEventListener('scroll', toggleVisibility);
	window.removeEventListener('beforeunload', () => {
		$cathodeClient.fireAtWindowClose();
	});
});

watch(
	() => route.path, // watch for route changes
	(newValue, oldValue) => {
		if (
			newValue === '/profile' &&
			oldValue !== '/orders' &&
			oldValue !== '/wishlist' &&
			oldValue !== '/address-book'
		) {
			localStorage.setItem('beforeProfile', oldValue);
		}
		$eventClient.sendEvent('page-view', {
			location: window.location.href,
			referrer: window.location.origin + oldValue,
		});

		FBConversion.apiCall({
			event_name: 'page-view',
		});
	},
);

watch(
	() => route.fullPath,
	() => {
		// preserve utm meta
		if (import.meta.server) {
			return;
		}
		if (sessionStorage.getItem('campaign')) {
			const location = window.location.href;
			const utms = sessionStorage.getItem('campaign')!.split('&');
			const searchParams = new URL(location).searchParams;
			utms.forEach((utm) => {
				const [key, value] = utm.split('=');
				if (!searchParams.has(key!)) {
					searchParams.append(key!, value!);
				}
			});
			history.replaceState(
				{},
				'',
				`${window.location.pathname}?${searchParams.toString()}`,
			);
		}
	},
);

provide('headerVisible', visible);
provide('checkoutLocalization', checkoutLocalization);
provide('newFilterUi', newFilterUi);
provide('deliveryOptionsExp', deliveryOptionsExp);
provide('globalCutoffConfig', globalCutoffConfig);
const storeMetadata = GlobalStore.store.meta_data;
defineOgImage({
	url:
		GlobalStore.storeType === 'OCTANE'
			? storeMetadata?.og_image
				? storeMetadata.og_image
				: storeMetadata?.logo
			: 'https://cdn.shopify.com/s/files/1/2337/7003/files/link-thumbnail-3.jpg?v=1722003616',
});

const setUserUUID = () => {
	const user_uuid = useCookie('user_uuid', { maxAge: 86400 * 14 });
	if (!user_uuid.value) {
		user_uuid.value = uuidv4();
	}
};
if (
	useRuntimeConfig().public.env === 'production' &&
	(url.host === 'laam.pk' || url.host === 'shop.laam.pk')
) {
	useHead({
		script: [
			{
				type: 'application/ld+json',
				children: [
					{
						'@context': 'https://schema.org',
						'@type': 'Organization',
						name: seoSchema.organisation.name,
						url: seoSchema.organisation.url,
						logo: 'https://cdn.shopify.com/s/files/1/2337/7003/files/laam_logo_svg.svg?v=1738576396',
						description: seoSchema.organisation.description,
						telephone: seoSchema.organisation.telephone,
						foundingDate: seoSchema.organisation.foundingDate,
						sameAs: [
							seoSchema.organisation.sameAsLinkedin,
							seoSchema.organisation.sameAsFacebook,
							seoSchema.organisation.sameAsInstagram,
							seoSchema.organisation.sameAsYoutube,
						],
						image: {
							'@type': 'ImageObject',
							url: 'https://cdn.shopify.com/s/files/1/2337/7003/files/laam_logo_svg.svg?v=1738576396',
						},
						founders: [
							{
								'@type': 'Person',
								name: 'Arif Iqbal',
							},
							{
								'@type': 'Person',
								name: 'Amir Iqbal',
							},
						],
						contactPoint: {
							'@type': 'ContactPoint',
							contactType: seoSchema.organisation.contactType,
							url: 'https://laam.pk/help-center',
							email: seoSchema.organisation.email,
						},
						address: {
							'@type': 'PostalAddress',
							addressLocality: seoSchema.organisation.addressLocality,
							addressRegion: seoSchema.organisation.addressRegion,
							postalCode: seoSchema.organisation.postalCode,
							addressCountry: 'Pakistan',
						},
					},
				],
			},
		],
	});
}

// change favicon for octane stores
useHead({
	link: [
		{
			rel: 'icon',
			href: '/favicon.ico',
		},
	],
});

const layout = computed(() => {
	const storeType = GlobalStore.store?.store_type;
	if (storeType === 'OCTANE') {
		return 'octane';
	} else return 'default';
});
</script>

<template>
	<ConfigProvider :use-id="useIdFunction">
		<NuxtLoadingIndicator />
		<StatsigExperimentProvider>
			<NuxtLayout :name="layout">
				<NuxtPage keep-alive />
			</NuxtLayout>
		</StatsigExperimentProvider>
	</ConfigProvider>
</template>

<style>
@import '@laam/ui/main.css';
</style>
