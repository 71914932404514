<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import { Primitive, type PrimitiveProps } from 'radix-vue';

import { cn } from '@laam/lib/utils';
import { type IconButtonVariants, iconButtonVariants } from '.';

interface Props extends PrimitiveProps {
	variant?: IconButtonVariants['variant'];
	size?: IconButtonVariants['size'];
	rounded?: IconButtonVariants['rounded'];
	class?: HTMLAttributes['class'];
	disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	as: 'button',
	variant: 'primary',
	size: 'xs',
	rounded: 'default',
	class: '',
	disabled: false,
});
</script>

<template>
	<Primitive
		:as="props.as"
		:as-child="props.asChild"
		:class="cn(iconButtonVariants({ variant, size, rounded }), props.class)"
		:disabled="props.disabled"
	>
		<slot />
	</Primitive>
</template>
