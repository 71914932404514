import * as Sentry from '@sentry/vue';
export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.config.errorHandler = (error) => {
		Sentry.captureException(error);
	};

	// Also possible
	nuxtApp.hook('vue:error', (error: any) => {
		Sentry.captureException(error, (scope) => {
			scope.setContext('data', error.data);
			return scope;
		});
	});
});
