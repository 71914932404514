/* eslint-disable @typescript-eslint/no-unused-vars */
import { type EventPayloads } from './events';

export class BaseTransformer {
	static transformAddToCartEvent(data: EventPayloads['add-to-cart']) {
		// eslint-disable-next-line unused-imports/no-unused-vars
		const { meta_data, ...rest } = data;
		return {
			data: rest,
		};
	}

	static transformADDTOCARTEvent(data: EventPayloads['AddToCart']) {
		// eslint-disable-next-line unused-imports/no-unused-vars
		return data;
	}

	static transformRemoveFromCartEvent(data: EventPayloads['remove-from-cart']) {
		// eslint-disable-next-line unused-imports/no-unused-vars
		const { meta_data, ...rest } = data;
		return {
			data: rest,
		};
	}
	static transformProceedToCheckoutEvent(
		data: EventPayloads['proceed-to-checkout'],
	) {
		// eslint-disable-next-line unused-imports/no-unused-vars
		const { meta_data, ...rest } = data;
		return {
			data: rest,
		};
	}
	static transformAddToWishlistEvent(
		data: EventPayloads['wishlist-add-product'],
	) {
		// eslint-disable-next-line unused-imports/no-unused-vars
		const { meta_data, ...rest } = data;
		return {
			data: rest,
		};
	}
	static transformPurchaseEvent(data: EventPayloads['purchase']) {
		// eslint-disable-next-line unused-imports/no-unused-vars
		const { lineItems, ...rest } = data;
		return {
			data: rest,
		};
	}
}
