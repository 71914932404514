import { defineStore } from 'pinia';
import countries from '../utils/countries.json';

export const useCountryCode = defineStore('countryCode', () => {
	const countryCode = ref('');

	const setCountryCode = (newValue: string) => {
		countryCode.value = newValue;
	};
	// country cookie
	const deliverToCountryCookie = useCookie('deliverToCountry', {
		maxAge: 86400, //cookie max age set to one day
		sameSite: 'strict',
	});
	// province cookie
	const deliverToProvinceCookie = useCookie('deliverToProvince', {
		maxAge: 86400, //cookie max age set to one day
		sameSite: 'strict',
	});
	//
	const deliverToCountry = ref('');
	const deliverToProvince = ref('');
	// persist function
	const persist = () => {
		deliverToCountryCookie.value = deliverToCountry.value;
		deliverToProvinceCookie.value = deliverToProvince.value;
	};
	// hydrate function
	const hydrate = () => {
		const deliverToCountryCookieValue = deliverToCountryCookie.value;
		const deliverToProvinceCookieValue = deliverToProvinceCookie.value;
		// for country
		if (deliverToCountryCookieValue) {
			deliverToCountry.value = countries[
				deliverToCountryCookieValue as keyof typeof countries
			]
				? deliverToCountryCookieValue
				: 'US';
		} else {
			// deliverToCountry.value = countryCode.value || 'PK';
			deliverToCountry.value = countries[
				countryCode.value as keyof typeof countries
			]
				? countryCode.value
				: 'US';
			persist();
		}
		// for province
		if (deliverToProvinceCookieValue) {
			deliverToProvince.value = deliverToProvinceCookieValue;
		} else {
			deliverToProvince.value = '';
			persist();
		}
	};
	const setDeliverToCountry = (newValue: string) => {
		deliverToCountry.value = newValue;
		persist();
	};
	const setDeliverToProvince = (newValue: string) => {
		deliverToProvince.value = newValue;
		persist();
	};
	onServerPrefetch(() => {
		hydrate();
	});
	return {
		countryCode,
		deliverToCountry,
		deliverToProvince,
		setCountryCode,
		setDeliverToCountry,
		setDeliverToProvince,
	};
});
