export const PAYMENT_METHODS = Object.freeze({
	COD: 'Cash on Delivery (COD)' as const,
	BANK_DEPOSIT: 'Bank Deposit',
	BANK_DEPOSIT_DISABLED: 'Bank Deposit (Disabled)',
	CREDIT_CARD: 'Credit/Debit Card (Local)',
	CREDIT_CARD_INTL: 'Credit/Debit Card (International)',
});
export const PAYMENT_METHODS_NEW = Object.freeze({
	COD: 'Cash on Delivery (COD)' as const,
	BANK_DEPOSIT: 'Bank Deposit',
	BANK_DEPOSIT_DISABLED: 'Bank Deposit (Disabled)',
	XPAY: 'Credit/Debit Card (Local)',
	STRIPE_PAYMENT: 'Credit/Debit Card (International)',
});
export const XPAY = ['xPay', 'xpay-jazz'];
export const STRIPE = 'Stripe Payment';

export const FORM_FIELDS = Object.freeze({
	ADDRESS1: 'address1',
	ADDRESS2: 'address2',
	FIRSTNAME: 'firstName',
	LASTNAME: 'lastName',
	PROVINCE: 'province',
	COUNTRY: 'country',
	CITY: 'city',
	COMPANY: 'company',
	PHONE: 'phone',
	ZIP: 'zip',
});

export const FORM_FIELD_TYPES = Object.freeze({
	CITY_DROPDOWN: 'city-dropdown',
	TEXT: 'text',
	SELECT: 'select',
});

export const PROMOTION_CODES = Object.freeze({
	SHIP_FREE: 'shipfree',
});

export const BILLING_ADDRESS_OPTIONS = Object.freeze({
	SAME: 'Same as shipping address',
	DIFFERENT: 'Use a different billing address',
});

export const CURRENCIES = Object.freeze({
	usd: {
		symbol: '$',
		code: 'usd',
	},
	cad: {
		symbol: '$',
		code: 'cad',
	},
	eur: {
		symbol: '€',
		code: 'eur',
	},
	gbp: {
		symbol: '£',
		code: 'gbp',
	},
	aed: {
		symbol: 'AED',
		code: 'aed',
	},
	inr: {
		symbol: '₹',
		code: 'inr',
	},
	bdt: {
		symbol: '৳',
		code: 'bdt',
	},
	aud: {
		symbol: '$',
		code: 'aud',
	},
	pkr: {
		symbol: 'PKR',
		code: 'pkr',
	},
	sar: {
		symbol: 'SAR',
		code: 'sar',
	},
	nzd: {
		symbol: '$',
		code: 'nzd',
	},
});

export type CURRENCY = keyof typeof CURRENCIES;

export const countriesCurrencyMap: { [key: string]: string } = {
	PK: 'pkr',
	US: 'usd',
	AE: 'aed',
	GB: 'gbp',
	CA: 'cad',
	AU: 'aud',
	IN: 'inr',
	SA: 'sar',
	AT: 'eur',
	BE: 'eur',
	HR: 'eur',
	CY: 'eur',
	EE: 'eur',
	FI: 'eur',
	FR: 'eur',
	DE: 'eur',
	GR: 'eur',
	IE: 'eur',
	IT: 'eur',
	LV: 'eur',
	LT: 'eur',
	LU: 'eur',
	MT: 'eur',
	NL: 'eur',
	PT: 'eur',
	SK: 'eur',
	SI: 'eur',
	ES: 'eur',
	NZ: 'nzd',
};

// sources of data for laam.pk product cards
export const DataSourceEnum = Object.freeze({
	VALHALLA: 'valhalla',
	DIRECT: 'direct',
	STOREFRONT: 'storefront',
});

export const DataSourceEnumMap = Object.freeze({
	storefront: 'sf',
	valhalla: 'v',
});

/* 
List of features that lead to product views & add to carts
This enum would be used for clicked_from field in firing events to statsig and cathode
clicked_from would be an array of strings e.g user clicks on quick-view via the syte search: 
clicked_from = ["search", "quick_view"]  & data_source = ["syte"]
*/
export const FeatureEnum = Object.freeze({
	BRAND_PAGE_CAROUSEL: 'brand_page_carousel',
	CHATBOT: 'chatbot',
	COLLECTION_LISTING: 'collection_listing',
	FLASH_SALE_CAROUSEL: 'flash_sale_carousel',
	HOMEPAGE_LISTING: 'homepage_listing',
	JUST_FOR_YOU_CAROUSEL: 'just_for_you_carousel',
	MORE_FROM_BRAND_CAROUSEL: 'more_from_brand_carousel',
	MORE_FROM_SAME_CATEGORY_CAROUSEL: 'more_from_same_category_carousel',
	MORE_FROM_THE_COLLECTION_CAROUSEL: 'more_from_the_collection_carousel',
	MORE_WITH_SIMILAR_DELIVERY_CAROUSEL: 'more_with_similar_delivery_carousel',
	MOST_LIKED_CAROUSEL: 'most_liked_carousel',
	MOST_LIKED_DISCOUNTED_CAROUSEL: 'most_liked_discounted_carousel',
	NEXT_PREV: 'next_prev',
	QUICK_VIEW: 'quick_view',
	SEARCH: 'search',
	SEARCH_BY_IMAGE: 'search_by_image',
	SIMILAR_PRODUCTS_CAROUSEL: 'similar_products_carousel',
	WISHLIST: 'wishlist',
	WISHLIST_CAROUSEL: 'wishlist_carousel',
	YOU_MAY_ALSO_LIKE_CAROUSEL: 'you_may_also_like_carousel',
});

/*
Mapping enum used for using short forms in query params for better visibility. Query params being used:
feat: FeatureEnumMap[FeatureEnum.KEY]
ver: valhalla version returned from products API (node-api)
*/
export const FeatureEnumMap = Object.freeze({
	brand_page_carousel: 'bpc',
	collection_listing: 'cl',
	flash_sale_carousel: 'fsc',
	homepage_listing: 'hl',
	just_for_you_carousel: 'jfc',
	more_from_same_category_carousel: 'mfscc',
	more_from_brand_carousel: 'mfbc',
	most_liked_discounted_carousel: 'moldc',
	most_liked_carousel: 'mlc',
	more_from_the_collection_carousel: 'mftcc',
	more_with_similar_delivery_carousel: 'mwsdc',
	np: 'next_prev',
	quick_view: 'qv',
	search: 's',
	search_by_image: 'sbi',
	similar_products_carousel: 'spc',
	wishlist: 'wl',
	wishlist_carousel: 'wl_c',
	you_may_also_like_carousel: 'ymalc',
});

export const REWARD_TYPES = Object.freeze({
	EARNED: 'EARNED',
	TO_BE_EARNED: 'TO_BE_EARNED',
	REDEEMED: 'REDEEMED',
	EXPIRED: 'EXPIRED',
	REFUNDED: 'REFUNDED',
	RETURNED_TO_LAAM: 'RETURNED_TO_LAAM',
	CANCELLED: 'CANCELLED',
});

export const PROTECTED_ROUTES = ['/rewards', '/wishlist', '/orders'];

export const USTAXEDSTATES = ['GA', 'IL', 'MD', 'MI', 'OH', 'VA', 'WA'];

export const SEARCH_NODES = Object.freeze([
	{
		name: 'All',
		id: null,
	},
	{
		name: 'Men',
		id: 21,
	},
	{
		name: 'Women',
		id: 1,
	},
	{
		name: 'Kids',
		id: 10000,
	},
]);

export const DuplicateOrderMessage = Object.freeze({
	DUPLICATE_ORDER: 'duplicateOrder',
});

export const pkPhoneNumberRegex =
	/^(3[0-9]{9}|03[0-9]{9}|00923[0-9]{9}|\+9203[0-9]{9}|\+923[0-9]{9}|923[0-9]{9})$/;

export const loganPhRegexString =
	'^(3[0-9]{9}|03[0-9]{9}|00923[0-9]{9}|\\+923[0-9]{9}|\\+9203[0-9]{9}|923[0-9]{9})$';

export const COUNTRY_CURRENCY_MAP = {
	pkr: {
		code: 'pkr',
		name: 'PKR',
		countryCode: 'PK',
		flag: 'https://hatscripts.github.io/circle-flags/flags/pk.svg',
	},
	usd: {
		code: 'usd',
		name: 'USD',
		countryCode: 'US',
		flag: 'https://hatscripts.github.io/circle-flags/flags/us.svg',
	},
	cad: {
		code: 'cad',
		name: 'CAD',
		countryCode: 'CA',
		flag: 'https://hatscripts.github.io/circle-flags/flags/ca.svg',
	},
	gbp: {
		code: 'gbp',
		name: 'GBP',
		countryCode: 'GB',
		flag: 'https://hatscripts.github.io/circle-flags/flags/gb.svg',
	},
	aed: {
		code: 'aed',
		name: 'AED',
		countryCode: 'AE',
		flag: 'https://hatscripts.github.io/circle-flags/flags/ae.svg',
	},
	sar: {
		code: 'sar',
		name: 'SAR',
		countryCode: 'SA',
		flag: 'https://hatscripts.github.io/circle-flags/flags/sa.svg',
	},
	eur: {
		code: 'eur',
		name: 'EUR',
		countryCode: 'EU',
		flag: 'https://hatscripts.github.io/circle-flags/flags/eu.svg',
	},
	aud: {
		code: 'aud',
		name: 'AU',
		countryCode: 'AU',
		flag: 'https://hatscripts.github.io/circle-flags/flags/au.svg',
	},
	inr: {
		code: 'inr',
		name: 'INR',
		countryCode: 'IN',
		flag: 'https://hatscripts.github.io/circle-flags/flags/in.svg',
	},
	nzd: {
		code: 'nzd',
		name: 'NZD',
		countryCode: 'NZ',
		flag: 'https://hatscripts.github.io/circle-flags/flags/nz.svg',
	},
};

export const COUPON_DISCOUNT_TYPE = Object.freeze({
	PERCENTAGE: 'PERCENTAGE',
	AMOUNT: 'AMOUNT',
});

export const COUPON_TYPE = Object.freeze({
	FLAT: 'FLAT',
	SHIPPING: 'SHIPPING',
});

export const ORDER_ITEM_PARENTS = Object.freeze({
	OOS: 'OOS',
	JEWELRY: 'JEWELRY',
});

export const SHIPPING_RESPONSE_CODES = Object.freeze({
	DEFAULT: 200,
	NO_PREMIUM: 219,
	NO_SHIPPING: 419,
});

export const EU_COUNTRIES = [
	'AT',
	'BE',
	'HR',
	'CY',
	'EE',
	'FI',
	'FR',
	'DE',
	'GR',
	'IE',
	'IT',
	'LV',
	'LT',
	'LU',
	'MT',
	'NL',
	'PT',
	'SK',
	'SI',
	'ES',
];

export const ORDER_CANCEL_REASONS = [
	'The product has been delayed',
	'Change of mind',
	'Placed a new order',
	'Delivery timeframe is too long',
	'This order is a duplicate',
	'Ordered the wrong size',
	'Other',
];
