import { globalStoreSchema, type GlobalStoreResponse } from '@laam/cms-shared';
import { validatorFactory } from '@laam/lib/validator';
import type { ConversionRates } from '~/stores/global';

const validator = validatorFactory<GlobalStoreResponse>(globalStoreSchema);

export const fetchStore = async () => {
	if (process.env.STORE_TYPE === 'octane') {
		return;
	}
	const api = createApiInstance();
	const response = await api.get('/v1/store');
	if (response.status === 200) {
		try {
			const { store, global_sections } = validator.verify(response.data);
			return { store, global_sections };
		} catch (error) {
			throw new Error('Failed to validate global store');
		}
	} else {
		console.info('STATUS', response.status);
		console.info('DATA', await response.data);
		throw new Error('Failed to fetch global store');
	}
};

export const fetchConversionRates = async (domain: string) => {
	return $fetch<ConversionRates>(`/api/currency-rate?store_domain=${domain}`);
};
