import axios from 'axios';
import { getStoreIdentifier } from './helpers';
import { refreshAuthTokens } from '~/data/logan';

const getBaseUrl = () => {
	if (process?.client) {
		const config = useRuntimeConfig();
		return config.public.apiBaseUrl as string;
	} else {
		return process.env.NUXT_PUBLIC_API_BASE_URL as string;
	}
};

const getStoreIdentifierParam = () => {
	if (process?.client) {
		const url = new URL(window.location.href);
		return url.searchParams.get('store_id') ?? undefined;
	} else {
		const url = useRequestURL();
		return url.searchParams.get('store_id') ?? undefined;
	}
};

const creatApiInstance = () => {
	const api = axios.create({
		baseURL: getBaseUrl(),
	});
	const storeIdentifier = getStoreIdentifier(getStoreIdentifierParam());

	// Request interceptor for API calls
	api.interceptors.request.use(
		async (config) => {
			if (!config.headers['Content-Type'])
				config.headers['Content-Type'] = 'application/json';

			if (process.client) {
				const payload = localStorage.getItem('logan')
					? JSON.parse(localStorage.getItem('logan')!)
					: '';
				const statsigStableId =
					localStorage.getItem('STATSIG_LOCAL_STORAGE_STABLE_ID') || '';
				config.headers['logan-token'] = payload.accessToken || '';
				config.headers['statsig-stable-id'] = statsigStableId;
			}
			config.headers['store-identifier'] = storeIdentifier;
			return config;
		},
		(error) => {
			Promise.reject(error);
		},
	);
	// Response interceptor for API calls
	api.interceptors.response.use(
		(response) => {
			return response;
		},
		async function (error) {
			if (!axios.isAxiosError(error)) {
				console.log('Error is not an Axios error', error);
				return Promise.reject(error);
			} else if (!error.response) {
				console.log('Error has no response', error);
				return Promise.reject(error);
			} else if (!error.config) {
				console.log('Error has no config', error);
				return Promise.reject(error);
			}
			const originalRequest = error.config;
			const retryCount = originalRequest.headers.get('Retry-Count') || 0;
			if (error.response.status === 401 && !retryCount && process.client) {
				const refreshToken = localStorage.getItem('logan')
					? JSON.parse(localStorage.getItem('logan')!).refreshToken
					: '';
				if (!refreshToken) {
					return Promise.reject(error);
				}
				originalRequest.headers['Retry-Count'] = 1;
				const response = await refreshAuthTokens(refreshToken);

				const newAccessToken = response.data.access_token;
				const newRefreshToken = response.data.refresh_token;

				const logan = JSON.parse(localStorage.getItem('logan')!);
				logan.accessToken = newAccessToken;
				logan.refreshToken = newRefreshToken;
				localStorage.setItem('logan', JSON.stringify({ ...logan }));
				// Refresh token code here
				axios.defaults.headers.common['logan-token'] = newAccessToken;
				return api(originalRequest);
			}
			return Promise.resolve(error.response);
		},
	);
	return api;
};

export default creatApiInstance;
