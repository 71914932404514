/* eslint-disable quotes */
const seoSchema = {
	organisation: {
		name: 'Laam',
		url: 'https://laam.pk',
		logo: '',
		description:
			"LAAM is a leading fashion platform that aims to revolutionize how people discover and purchase South Asian fashion through its technology-enabled marketplace. Unlike other simple e-commerce websites, LAAM is hyper-focused on customer experience, utilizing AI to understand each user's style and browsing behavior to create a personalized online journey that enhances the shopping experience and increases customer satisfaction. LAAM also provides visual inspirations tailored to the shopper, including lookbooks, style guides, and current fashion trends. The combination of personalization and data-driven strategies ensures an engaging and relevant fashion journey for every user. Furthermore, LAAM also aims to enable South Asian fashion brands by providing them with the necessary tools to scale and digitize their businesses. To achieve this, LAAM not only offers a platform for brands to showcase their products to a global customer base but provides 'Fulfillment-by-LAAM,' end-to-end management of the entire supply chain, including advanced warehousing, shipping, and data analytics for companies to make informed business decisions regarding inventory, supply and demand, and more.",
		telephone: 923167776158,
		foundingDate: '1/1/2021',
		sameAsLinkedin: 'https://pk.linkedin.com/company/laamworld',
		sameAsFacebook: 'https://www.facebook.com/laamworld/',
		sameAsInstagram: 'https://www.instagram.com/laamofficial/',
		sameAsYoutube: 'https://www.youtube.com/@LAAMYT',
		contactType: 'customer service',
		email: 'customercare@laam.pk',
		addressLocality: 'Lahore',
		addressRegion: 'Pakistan',
		postalCode: '54000',
	},
	webpage: {
		name: "Shop Online at LAAM | Pakistan's Largest Fashion Discovery Platform",
		url: 'https://laam.pk',
		description:
			'Discover designer wear and top brands at LAAM.pk, Pakistan’s ultimate fashion destination. Shop clothing, accessories, footwear, and more with worldwide delivery and a seamless shopping experience.',
		image:
			'https://cdn.shopify.com/s/files/1/2337/7003/files/laam_logo_svg.svg?v=1738576396',
	},

	women: {
		name: "Women's Fashion at LAAM | Clothing, Footwear & Accessories",
		description:
			'Discover the latest in women’s fashion at LAAM.pk. Shop designer wear, eastern & western clothing, modest wear, accessories, footwear, and more, all with worldwide delivery and effortless shopping.',
		image: 'https://laam.pk/seo/women-img.webp',
	},
	men: {
		name: "Men's Collection at LAAM.pk | Clothing, Footwear & Accessories",
		description:
			'Explore the best in men’s fashion at LAAM.pk. Shop designer wear, eastern & western clothing, activewear, accessories, footwear, and more with worldwide delivery and easy shopping.',
		image: 'https://laam.pk/seo/men-img.webp',
	},
	kids: {
		name: "Kids' Collection at LAAM.pk | Clothing, Footwear & Accessories",
		description:
			'Shop the latest styles for kids at LAAM.pk. Discover clothing, footwear, accessories, and more for boys and girls, with worldwide delivery and a seamless shopping experience.',
		image: 'https://laam.pk/seo/kids-img.webp',
	},
};

export default seoSchema;
