<script setup lang="ts">
import { StatsigSingleton } from '@laam/statsig-client';
import Statsig, { type StatsigUser } from 'statsig-node';
import {
	newArrivalPageExperimentInjectionKey,
	newArrivalPLPExperimentInjectionKey,
	newArrivalsFilterExperimentInjectionKey,
	PDPRedesignExperimentInjectionKey,
	hideExpressTogglePLPExperimentInjectionKey,
	CartShippingExperimentInjectionKey,
	type CartShippingExperiment,
	type HideExpressTogglePLPExperiment,
	type PDPRedesignExperiment,
	type NewArrivalsFilterExperiment,
	type NewArrivalsPageExperiment,
	type NewArrivalsPLPExperiment,
	checkoutRedesignExperimentInjectionKey,
	type SearchButtonV2Experiment,
	searchButtonV2ExperimentInjectionKey,
} from '~/utils/use-statsig-experiment';

const newArrivalsPLPExperiment = ref<NewArrivalsPLPExperiment>();
const deliveryMethodsExperiment = ref<boolean>(false);
const newArrivalPageExperiment = ref<NewArrivalsPageExperiment>();
const newArrivalsFilterExperiment = ref<NewArrivalsFilterExperiment>();
const hideExpressTogglePLPExperiment = ref<HideExpressTogglePLPExperiment>();
const pdpRedesignExperiment = useState<PDPRedesignExperiment | undefined>(
	'pdpRedesign',
	() => undefined,
);
const searchButtonV2Experiment = useState<SearchButtonV2Experiment | undefined>(
	'search-button-v2',
	() => undefined,
);
const checkoutRedesignExperiment = ref<boolean>(true);
const globalCutoffConfig = ref();
const cartShippingExperiment = ref<CartShippingExperiment>();

onServerPrefetch(async () => {
	const stableId = useCookie('statsig-stable-id');
	const user: StatsigUser = {
		customIDs: {
			stableID: stableId.value!,
		},
	};
	if (stableId.value) {
		pdpRedesignExperiment.value = Statsig.getExperiment(user, 'pdp-redesign-v2')
			.value as PDPRedesignExperiment;
		searchButtonV2Experiment.value = Statsig.getExperiment(
			user,
			'search-on-homepage',
		).value as SearchButtonV2Experiment;
	}
});

onMounted(async () => {
	newArrivalsPLPExperiment.value = StatsigSingleton.getExperiment(
		'new-arrival-plp',
	).value as NewArrivalsPLPExperiment;
	deliveryMethodsExperiment.value = StatsigSingleton.getExperiment(
		'delivery-methods',
	).get('Allowed', false);
	newArrivalPageExperiment.value = StatsigSingleton.getExperiment(
		'new-arrivals-page',
	).value as NewArrivalsPageExperiment;
	newArrivalsFilterExperiment.value = StatsigSingleton.getExperiment(
		'new-filter-plp',
	).value as NewArrivalsFilterExperiment;
	searchButtonV2Experiment.value = StatsigSingleton.getExperiment(
		'search-on-homepage',
	).value as SearchButtonV2Experiment;
	hideExpressTogglePLPExperiment.value = StatsigSingleton.getExperiment(
		'hide-express-toggle-plp',
	).value as HideExpressTogglePLPExperiment;
	globalCutoffConfig.value = StatsigSingleton.getDynamicConfig(
		'shipping-cutoff-config',
	).value;
	cartShippingExperiment.value = StatsigSingleton.getExperiment(
		'shipping-in-cart',
	).value as CartShippingExperiment;
});

provide(newArrivalPLPExperimentInjectionKey, newArrivalsPLPExperiment);
provide(deliveryMethodsExperimentInjectionKey, deliveryMethodsExperiment);
provide(newArrivalPageExperimentInjectionKey, newArrivalPageExperiment);
provide(newArrivalsFilterExperimentInjectionKey, newArrivalsFilterExperiment);
provide(PDPRedesignExperimentInjectionKey, pdpRedesignExperiment);
provide(checkoutRedesignExperimentInjectionKey, checkoutRedesignExperiment);
provide(searchButtonV2ExperimentInjectionKey, searchButtonV2Experiment);
provide(
	hideExpressTogglePLPExperimentInjectionKey,
	hideExpressTogglePLPExperiment,
);
provide(CartShippingExperimentInjectionKey, cartShippingExperiment);
provide('globalCutoffConfig', globalCutoffConfig);
</script>
<template>
	<slot />
</template>
