import axios from 'axios';
import type {
	CathodeEventPayload,
	CathodeImpressionsPayload,
	CathodeEventsMetaData,
	CathodeEventsMetaDataTypes,
	UserObjectType,
	CathodeImpressionsMetaDataTypes,
	CathodeImpressionsMetaData,
} from './events';

export class CathodeEventClient {
	private readonly baseUrl: string;
	private readonly userObject: UserObjectType;

	eventsQueue: CathodeEventPayload[] = [];
	impressionsQueue: CathodeImpressionsPayload[] = [];
	eventWindow = 5000;
	apiWorker: Worker | null = null;

	constructor(baseUrl: string, userObject: UserObjectType) {
		this.baseUrl = baseUrl;
		this.userObject = userObject;

		this.initializeQueue();
	}

	// main function that sends the events
	async sendEvent(events: CathodeEventPayload[] | CathodeImpressionsPayload[]) {
		const url = this.baseUrl;
		try {
			await axios.post(url, events);
		} catch (error) {
			console.error('Error sending event:', error);
		}
	}

	// sends the events when the window is closed
	fireAtWindowClose() {
		const eventsSnapshot = this.eventsQueue.splice(0, this.eventsQueue.length);
		if (eventsSnapshot.length > 0) {
			this.sendEvent(eventsSnapshot);
		}

		const impressionsSnapshot = this.eventsQueue.splice(
			0,
			this.eventsQueue.length,
		);
		if (impressionsSnapshot.length > 0) {
			this.sendEvent(impressionsSnapshot);
		}
	}

	// initializes the timout function that calls sendEvent
	initializeQueue() {
		setInterval(() => {
			this.fireEventAfterInterval();
			this.fireImpressionAfterInterval();
		}, this.eventWindow);
	}

	/* FOR EVENTS */
	queueEvent<T extends CathodeEventsMetaDataTypes>(
		name: T,
		data: CathodeEventsMetaData[T],
	): void {
		const payload: CathodeEventPayload = {
			event_type: name,
			user: this.userObject,
			metadata: data,
		};
		this.collectEvents(payload);
	}

	collectEvents(event: CathodeEventPayload) {
		this.eventsQueue.push(event);
	}

	fireEventAfterInterval() {
		const eventsSnapshot = this.eventsQueue.splice(0, this.eventsQueue.length);
		if (eventsSnapshot.length > 0) {
			this.sendEvent(eventsSnapshot);
		}
	}

	/* FOR IMPRESSIONS */
	queueImpression<T extends CathodeImpressionsMetaDataTypes>(
		name: T,
		data: CathodeImpressionsMetaData[T],
	): void {
		const payload: CathodeImpressionsPayload = {
			event_type: name,
			user: this.userObject,
			metadata: data,
		};
		this.impressionsQueue.push(payload);
	}

	getEventKey(event: CathodeImpressionsPayload) {
		const eventType = event.event_type;
		const source = event.metadata.source;
		const time = event.metadata.time;
		// @ts-ignore
		const verticalIndex = event.metadata.vertical_index;
		// @ts-ignore
		const component = event.metadata.component;

		return `${eventType}-${source}-${time}-${verticalIndex}-${component}`;
	}

	fireImpressionAfterInterval() {
		const impressionsSnapshot = this.impressionsQueue.splice(
			0,
			this.impressionsQueue.length,
		);
		if (impressionsSnapshot.length > 0) {
			const groupedEvents: { [key: string]: CathodeImpressionsPayload } = {};

			impressionsSnapshot.forEach((event) => {
				if (event.metadata.data.length > 1) {
					return;
				}
				// Create a key based on all properties except the data array
				const key = this.getEventKey(event);

				if (!groupedEvents[key]) {
					// If the key doesn't exist, add the event to the dictionary
					groupedEvents[key] = {
						...event,
						metadata: {
							...event.metadata,
							data: event.metadata
								.data as CathodeImpressionsMetaData['page_view']['data'],
						},
					};
				} else {
					// If the key exists, merge the data arrays
					groupedEvents[key]!.metadata.data.push(
						...(event.metadata.data as any[]),
					);
				}
			});

			// Convert the dictionary back to an array
			this.sendEvent(Object.values(groupedEvents));
		}
	}
}
