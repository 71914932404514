import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import type { Updater } from '@tanstack/vue-table';
import type { Ref } from 'vue';

import useScreenSize from './screenSize';

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function valueUpdater<T extends Updater<any>>(
	updaterOrValue: T,
	ref: Ref,
) {
	ref.value =
		typeof updaterOrValue === 'function'
			? updaterOrValue(ref.value)
			: updaterOrValue;
}

export function convertDatetimeString(inputString: string): string | null {
	const parsedDate = new Date(inputString);
	if (isNaN(parsedDate.getTime())) {
		return null;
	}
	const year = parsedDate.getFullYear().toString().padStart(4, '0');
	const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
	const day = parsedDate.getDate().toString().padStart(2, '0');
	const hours = parsedDate.getHours().toString().padStart(2, '0');
	const minutes = parsedDate.getMinutes().toString().padStart(2, '0');
	const seconds = parsedDate.getSeconds().toString().padStart(2, '0');
	return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

export { useScreenSize };

import type { ProductSchema } from '@laam/cms-shared';

type Variants = ProductSchema['variants'];

export const calculatePricing = (variants: Variants, hvc: boolean = false) => {
	// get the cheapest variant
	const cheapestVariant = variants
		?.filter((variant) => variant !== null)
		.reduce((prev, current) =>
			parseFloat(prev.price) < parseFloat(current.price) ? prev : current,
		);

	if (!cheapestVariant) {
		console.log('cheapestVariant is undefined');
		return;
	}
	const originalPrice = parseFloat(cheapestVariant.price);
	const compareAtPrice = parseFloat(cheapestVariant.compare_at_price);
	const hvcPrice =
		hvc && cheapestVariant.reseller_price
			? cheapestVariant.reseller_price!
			: null;
	if (isNaN(originalPrice)) {
		console.log('Invalid Price');
		return;
	}
	if (
		!isNaN(compareAtPrice) &&
		!!compareAtPrice &&
		compareAtPrice < originalPrice
	) {
		return {
			discountedPrice: null,
			originalPrice: originalPrice,
			compareAtPrice: compareAtPrice,
			hvcPrice: null,
			discountPercentage: null,
		};
	}

	const discountPercentage =
		cheapestVariant && !isNaN(compareAtPrice) && compareAtPrice > originalPrice
			? Math.round(((compareAtPrice - originalPrice) / compareAtPrice) * 100)
			: null;

	return {
		discountedPrice: discountPercentage ? originalPrice : null,
		originalPrice: discountPercentage ? compareAtPrice : originalPrice,
		compareAtPrice: compareAtPrice,
		hvcPrice: hvcPrice,
		discountPercentage,
	};
};

export const convertToBase64 = (file: File) => {
	const reader = new FileReader();
	return new Promise<string>((resolve, reject) => {
		reader.onloadend = () => {
			resolve(reader.result as string);
		};
		reader.onerror = () => {
			reject(reader.error);
		};
		reader.readAsDataURL(file);
	});
};

type ImageValidationResult =
	| {
			valid: true;
			// eslint-disable-next-line no-mixed-spaces-and-tabs
	  }
	| {
			valid: false;
			error: string;
			// eslint-disable-next-line no-mixed-spaces-and-tabs
	  };

export const validateImageDimensions = async (
	file: File,
	limitType: string,
	maxWidth?: number,
	maxHeight?: number,
) => {
	const content = URL.createObjectURL(file);
	const img = new Image();
	img.src = content;
	return new Promise<ImageValidationResult>((resolve, reject) => {
		img.onload = () => {
			if (limitType == 'max') {
				if (maxWidth && img.width > maxWidth) {
					reject(`Image width should be less than or equal to ${maxWidth}px`);
				} else if (maxHeight && img.height > maxHeight) {
					reject(`Image height should be less than or equal to ${maxHeight}px`);
				}
			} else {
				if (maxWidth && img.width !== maxWidth) {
					reject(`Image width should be equal to ${maxWidth}px`);
				} else if (maxHeight && img.height !== maxHeight) {
					reject(`Image height should be equal to ${maxHeight}px`);
				}
			}
			resolve({ valid: true });
		};
	}).catch((error) => {
		return { valid: false, error };
	});
};
