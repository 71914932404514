import {
	type Section,
	type GlobalStoreResponse,
	type OctaneFooterData,
	type OctaneHeaderData,
} from '@laam/cms-shared';
import { defineStore } from 'pinia';
import { fetchConversionRates } from '~/data/global-store';
import type { AddOns } from '~/types/store';

export type ConversionRates = {
	[key: string]: {
		exchange_rate_to_pk: {
			rate: number;
			slab_end: number;
			slab_start: number;
		}[];
		exchange_rate_to_usd: number;
		default_rate: number;
	};
};

export const useGlobalStore = defineStore('global', () => {
	const store = useState<GlobalStoreResponse['store']>();
	const $conversionRates = useState<ConversionRates>();
	const storeType = ref<string | null>(null);
	const header = ref<OctaneHeaderData | null>(null);
	const footer = ref<OctaneFooterData | null>(null);
	const sections = ref<Section[]>([]);
	const domain = ref<string | null>(null);
	const criticalLatency = ref<number[]>([0, 0, 0]);

	const addOns = computed(() => {
		return store.value.add_ons as AddOns;
	});

	const headerLogo = computed(() => {
		if (!header.value) return null;
		return header.value.logo.url;
	});

	const initializeHeader = (
		globalSections: GlobalStoreResponse['global_sections'],
	) => {
		const headerSection = globalSections.find(
			(section) => section.component_identifier === 'header',
		);
		if (headerSection) {
			header.value = headerSection.meta_data as OctaneHeaderData;
		}
	};

	const initializeFooter = (
		globalSections: GlobalStoreResponse['global_sections'],
	) => {
		const footerSection = globalSections.find(
			(section) => section.component_identifier === 'footer',
		);
		if (footerSection) {
			footer.value = footerSection.meta_data as OctaneFooterData;
		}
	};

	const fetchStoreConfigAndGlobalSections = async () => {
		const siteConfig = useSiteConfig();
		const globalStore = siteConfig.store;
		const globalSections = siteConfig.global_sections;
		const storeLatency = siteConfig.latency;

		try {
			const startTime = Date.now();
			const currencyRates = await fetchConversionRates(globalStore.domain!);
			const endTime = Date.now();
			criticalLatency.value = [storeLatency, endTime - startTime, 0];
			$conversionRates.value = currencyRates;
		} catch (error) {
			console.error(error);
			$conversionRates.value = {};
		}

		store.value = globalStore;
		storeType.value = globalStore.store_type;
		if (storeType.value === 'OCTANE') {
			initializeHeader(globalSections);
			initializeFooter(globalSections);
		}
	};

	const setDomain = () => {
		domain.value = store.value.domain!;
	};

	return {
		store,
		storeType,
		$conversionRates,
		fetchStoreConfigAndGlobalSections,
		header,
		footer,
		sections,
		headerLogo,
		domain,
		addOns,
		criticalLatency,
		setDomain,
	};
});
