export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appHead = {"link":[],"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"property":"og:image","content":"og-image.jpg"},{"property":"og:image:type","content":"image/jpeg"},{"property":"og:image:width","content":"512"},{"property":"og:image:height","content":"268"},{"name":"twitter:image","content":"og-image.jpg"},{"name":"twitter:image:type","content":"image/jpeg"},{"name":"twitter:image:width","content":"512"},{"name":"twitter:image:height","content":"268"},{"property":"og:image","content":"og-image.png"},{"property":"og:image:type","content":"image/png"},{"property":"og:image:width","content":"512"},{"property":"og:image:height","content":"268"},{"name":"twitter:image","content":"og-image.png"},{"name":"twitter:image:type","content":"image/png"},{"name":"twitter:image:width","content":"512"},{"name":"twitter:image:height","content":"268"},{"property":"og:type","content":"website"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en"}}

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false