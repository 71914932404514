import { useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import {
	useSendOtp,
	useEnterOtp,
	useSendMergerOtp,
	useEnterMergerOtp,
	useDeleteAccount,
	useUpdateUserInfo,
	useHandleAddress,
	useGetUserInfo,
	useDeleteAddress,
} from '~/data/logan';
import countries from '~/utils/countries.json';
import { validateEmail } from '~/utils/checkout.ts';
export const useLoganStore = defineStore('logan', () => {
	// helpers
	const { $eventClient } = useNuxtApp();
	// states
	const invalidInput = ref(false);
	const invalidOtp = ref(false);
	const resendOtp = ref(false);
	const showLoginSheet = ref(false);
	const showOtpInputBox = ref(false);
	const showAlert = ref(false);
	const alertMessage = ref('');
	const showMergeSheet = ref(false);
	const existingAccount = ref(false);
	const showMergeSuccess = ref(false);
	const isOldUser = ref(true);
	const isCampusEmailVerificationRequested = ref(false);
	const isCampusDocVerificationRequested = ref(false);
	const showNewLogin = ref(false);

	const loganId = ref();
	const email = ref();
	const firstName = ref();
	const lastName = ref();
	const phoneNumber = ref();
	const shopifyId = ref();
	const addresses = ref();
	const defaultAddress = ref();
	const tags = ref();
	const isHvc = ref();
	const accessToken = ref();
	const refreshToken = ref();
	const cartID = ref();
	const isSignedIn = ref(false);
	const requiresSignIn = ref(false);
	const gender = ref();
	const dateOfBirth = ref();
	const storeId = ref();
	// persist function
	const persist = () => {
		const logan = useLocalStorage('logan', {
			loganId: undefined,
			email: undefined,
			firstName: undefined,
			lastName: undefined,
			phoneNumber: undefined,
			shopifyId: undefined,
			addresses: undefined,
			defaultAddress: undefined,
			tags: undefined,
			isHvc: undefined,
			accessToken: undefined,
			refreshToken: undefined,
			cartID: undefined,
			isSignedIn: false,
			gender: undefined,
			dateOfBirth: undefined,
			storeId: undefined,
		});
		logan.value.loganId = loganId.value;
		logan.value.email = email.value;
		logan.value.firstName = firstName.value;
		logan.value.lastName = lastName.value;
		logan.value.phoneNumber = phoneNumber.value;
		logan.value.shopifyId = shopifyId.value;
		logan.value.addresses = addresses.value;
		logan.value.defaultAddress = defaultAddress.value;
		logan.value.tags = tags.value;
		logan.value.isHvc = isHvc.value;
		logan.value.accessToken = accessToken.value;
		logan.value.refreshToken = refreshToken.value;
		logan.value.cartID = cartID.value;
		logan.value.isSignedIn = isSignedIn.value;
		logan.value.gender = gender.value;
		logan.value.dateOfBirth = dateOfBirth.value;
		logan.value.storeId = storeId.value;
	};
	// hydrate
	const hydrate = () => {
		const logan = useLocalStorage('logan', {
			loganId: undefined,
			email: undefined,
			firstName: undefined,
			lastName: undefined,
			phoneNumber: undefined,
			shopifyId: undefined,
			addresses: undefined,
			defaultAddress: undefined,
			tags: undefined,
			isHvc: undefined,
			accessToken: undefined,
			refreshToken: undefined,
			cartID: undefined,
			isSignedIn: false,
			gender: undefined,
			dateOfBirth: undefined,
			storeId: undefined,
		}).value;

		loganId.value = logan.loganId;
		email.value = logan.email;
		firstName.value = logan.firstName;
		lastName.value = logan.lastName;
		phoneNumber.value = logan.phoneNumber;
		shopifyId.value = logan.shopifyId;
		addresses.value = logan.addresses;
		defaultAddress.value = logan.defaultAddress;
		tags.value = logan.tags;
		isHvc.value = logan.isHvc;
		accessToken.value = logan.accessToken;
		refreshToken.value = logan.refreshToken;
		cartID.value = logan.cartID;
		isSignedIn.value = logan.isSignedIn;
		gender.value = logan.gender;
		dateOfBirth.value = logan.dateOfBirth;
		storeId.value = logan.storeId;
	};
	watch(showLoginSheet, (newValue, oldValue) => {
		if (newValue === false && oldValue === true) {
			invalidInput.value = false;
			invalidOtp.value = false;
			resendOtp.value = false;
			showLoginSheet.value = false;
			showOtpInputBox.value = false;
			showAlert.value = false;
			alertMessage.value = '';
			showMergeSheet.value = false;
			existingAccount.value = false;
			showMergeSuccess.value = false;
		}
	});

	onBeforeMount(async () => {
		hydrate();

		if (isSignedIn.value) {
			await nextTick(() => {
				getUserInfo();
			});
		}
	});
	// mutations
	const { mutate: sendOtpMutation, isPending: sendOtpLoading } = useSendOtp();
	const { mutate: enterOtpMutation, isPending: enterOtpLoading } =
		useEnterOtp();
	const { mutate: sendMergerOtpMutation, isPending: sendMergerLoading } =
		useSendMergerOtp();
	const { mutate: enterMergerOtpMutation, isPending: enterMergerLoading } =
		useEnterMergerOtp();
	const { mutate: deleteAccountMutation, isPending: deleteLoading } =
		useDeleteAccount();
	const { mutate: updateUserInfoMutation, isPending: updateLoading } =
		useUpdateUserInfo();
	const {
		mutateAsync: handleAddressMutation,
		isPending: handleAddressLoading,
	} = useHandleAddress();
	const { data, refetch, isLoading } = useGetUserInfo();
	useDeleteAddress;
	const {
		mutate: handleDeleteAddressMutation,
		isPending: handleDeleteAddressLoading,
	} = useDeleteAddress();
	// getters
	const loading = computed(() => {
		const loading =
			sendOtpLoading.value ||
			enterOtpLoading.value ||
			sendMergerLoading.value ||
			enterMergerLoading.value ||
			deleteLoading.value ||
			updateLoading.value ||
			handleAddressLoading.value ||
			handleDeleteAddressLoading.value;
		return loading;
	});
	const gettingUserInfo = computed(() => isLoading.value);

	// actions
	const verifyEmail = (email: string) => {
		const isValidEmail = validateEmail(email);
		if (!isValidEmail) {
			return triggerInvalidInput();
		}
		invalidInput.value = false;
		return true;
	};
	const verifyPhone = (phoneNumber: string) => {
		// hard coded for now
		const phoneRegex = countries.PK.pattern;
		const regexExpression = new RegExp(phoneRegex);
		const phoneValid = regexExpression.test(phoneNumber);

		if (!phoneValid) {
			return triggerInvalidInput();
		}
		invalidInput.value = false;
		return true;
	};
	const triggerInvalidInput = () => {
		invalidInput.value = true;
		// cancel timeout if value is changed
		setTimeout(() => {
			invalidInput.value = false;
		}, 5000);
		return false;
	};
	const triggerInvalidOtp = () => {
		invalidOtp.value = true;
		// cancel timeout if value is changed
		setTimeout(() => {
			invalidOtp.value = false;
		}, 5000);
		return false;
	};
	const triggerLoganAlert = (message: string) => {
		showAlert.value = true;
		alertMessage.value = message;
		setTimeout(() => {
			showAlert.value = false;
			alertMessage.value = '';
		}, 5000);
	};
	const sendOtp = async (
		isEmail: boolean,
		data: string,
		userInfo?: {
			firstName: string;
			lastName: string;
		},
	) => {
		type Body = {
			email: string | undefined;
			phone: string | undefined;
			logan_action: string | undefined;
			first_name: string | undefined;
			last_name: string | undefined;
		};
		const body: Body = {
			email: undefined,
			phone: undefined,
			logan_action: undefined,
			first_name: userInfo?.firstName,
			last_name: userInfo?.lastName,
		};
		const route = useRoute();
		if (isEmail) {
			body.email = data;
		} else {
			body.phone = data;
		}
		if (route.query.customerType === 'hvc') {
			body.logan_action = 'HVC_REQUESTED';
		}
		sendOtpMutation(body, {
			onSuccess(data) {
				isOldUser.value = data.logan.has_existing_customer!;
				showOtpInputBox.value = true;
				triggerLoganAlert('OTP code sent');
				$eventClient.sendEvent('user-otp-sent', {
					email: body.email,
					phone: body.phone,
					source: window.location.href,
				});
			},
			onError(error) {
				console.log(error, 'here');
			},
		});
	};
	const enterOtp = (
		isEmail: boolean,
		data: string,
		otpCode: string,
		subscribeToNewsLetter: boolean = true,
	) => {
		type Body = {
			logan_otp: string;
			phone: string | undefined;
			email: string | undefined;
		};
		const body: Body = {
			logan_otp: '',
			phone: undefined,
			email: undefined,
		};
		if (isEmail) {
			body.email = data;
		} else {
			body.phone = data;
		}
		body.logan_otp = otpCode;
		enterOtpMutation(body, {
			onSuccess(data) {
				const datum = data.user;
				loganId.value = datum.id;
				email.value = datum.email;
				firstName.value = datum.first_name;
				lastName.value = datum.last_name;
				phoneNumber.value = datum.phone;
				shopifyId.value = data.shopify_id;
				addresses.value = data.addresses;
				defaultAddress.value = data.default_address;
				tags.value = datum.tags;
				isHvc.value = datum.is_hvc;
				accessToken.value = data.access_token;
				refreshToken.value = data.refresh_token;
				gender.value = datum.gender;
				dateOfBirth.value = datum.date_of_birth;
				storeId.value = datum.store_id;
				// cartID.value = datum.cartID;
				showOtpInputBox.value = false;
				isSignedIn.value = true;
				if (email.value === null) {
					showMergeSheet.value = true;
				}
				showLoginSheet.value = false;
				// login on all adapters
				$eventClient.login({
					userId: loganId.value,
					email: email.value,
					phone: phoneNumber.value,
					subscribeNewsletter: {
						usingEmail: isEmail,
						subscribe: subscribeToNewsLetter,
					},
				});

				$eventClient.sendEvent('user-login', {
					userId: loganId.value,
					email: email.value,
					phone: phoneNumber.value,
					source: window.location.href,
				});
				persist();
			},
			onError(error) {
				$eventClient.sendEvent('user-login-failed', {
					userId: loganId.value,
					email: email.value,
					phone: phoneNumber.value,
					source: window.location.href,
				});
				console.log(error);
				triggerInvalidOtp();
				triggerLoganAlert('Invalid Code');
			},
		});
	};
	const sendMergerOtp = (isEmail: boolean, data: string) => {
		type Body = {
			email: string | undefined;
			phone: string | undefined;
		};
		const body: Body = {
			email: undefined,
			phone: undefined,
		};
		if (isEmail) {
			body.email = data;
		} else {
			body.phone = data;
		}
		sendMergerOtpMutation(body, {
			onSuccess(data) {
				if (data.logan.has_existing_customer === true) {
					existingAccount.value = true;
				}
				showOtpInputBox.value = true;
				triggerLoganAlert('OTP code sent');
			},
			onError(error) {
				console.log(error, 'here');
			},
		});
	};
	const enterMergerOtp = (isEmail: boolean, data: string, otpCode: string) => {
		type Body = {
			logan_otp: string;
			phone: string | undefined;
			email: string | undefined;
			store_id: number;
			logan_action: string;
		};
		const body: Body = {
			logan_otp: '',
			phone: undefined,
			email: undefined,
			store_id: storeId.value ? storeId.value : '',
			logan_action: '',
		};
		if (isEmail) {
			body.email = data;
			body.phone = phoneNumber.value;
		} else {
			body.phone = data;
			body.email = email.value;
		}
		body.logan_otp = otpCode;
		enterMergerOtpMutation(body, {
			onSuccess(data) {
				const datum = data.user;
				loganId.value = datum.id;
				email.value = datum.email;
				firstName.value = datum.first_name;
				lastName.value = datum.last_name;
				phoneNumber.value = datum.phone;
				shopifyId.value = data.shopify_id;
				addresses.value = data.addresses;
				defaultAddress.value = data.default_address;
				tags.value = datum.tags;
				isHvc.value = datum.is_hvc;
				accessToken.value = data.access_token;
				refreshToken.value = data.refresh_token;
				gender.value = datum.gender;
				dateOfBirth.value = datum.date_of_birth;
				// cartID.value = datum.cartID;
				showOtpInputBox.value = false;
				isSignedIn.value = true;
				showOtpInputBox.value = false;
				showMergeSuccess.value = true;
				persist();
			},
			onError(error) {
				console.log(error);
				triggerInvalidOtp();
				triggerLoganAlert('Invalid Code');
			},
		});
	};
	const deleteAccount = () => {
		const body = {
			logan_id: loganId.value,
			access_token: accessToken.value,
		};
		const router = useRouter();
		deleteAccountMutation(body, {
			onSuccess() {
				signOut();
				router.go(-1);
			},
		});
	};
	const updateUserInfo = (
		firstNameP: string,
		lastNameP: string,
		genderP: string,
		dob: string,
	) => {
		const body: {
			first_name: string;
			last_name: string;
			gender: string;
			date_of_birth: string;
			logan_action: string;
			deleted: boolean;
			tags: string[];
		} = {
			first_name: firstNameP,
			last_name: lastNameP,
			gender: genderP,
			date_of_birth: dob,
			logan_action: '',
			deleted: false,
			tags: [],
		};
		if (!tags.value) {
			body.tags = [];
		} else {
			body.tags = tags.value;
		}
		updateUserInfoMutation(body, {
			onSuccess(data) {
				const datum = data.user;
				loganId.value = datum.id;
				email.value = datum.email;
				firstName.value = datum.first_name;
				lastName.value = datum.last_name;
				phoneNumber.value = datum.phone;
				tags.value = datum.tags;
				isHvc.value = datum.is_hvc;
				gender.value = datum.gender;
				dateOfBirth.value = datum.date_of_birth;
				persist();
			},
			onError(error) {
				console.log(error);
			},
		});
	};
	const getUserInfo = async () => {
		await refetch();

		const datum = data.value?.data.user;
		loganId.value = datum.id;
		email.value = datum.email;
		firstName.value = datum.first_name;
		lastName.value = datum.last_name;
		phoneNumber.value = datum.phone;
		shopifyId.value = data?.value?.data.shopify_id;
		addresses.value = data.value?.data.addresses;
		defaultAddress.value = data.value?.data.default_address;
		tags.value = datum.tags;
		isHvc.value = datum.is_hvc;
		gender.value = datum.gender;
		dateOfBirth.value = datum.date_of_birth;
		storeId.value = datum.store_id;
		isSignedIn.value = true;
		if (data.value?.data.hvc_request_data) {
			const hvcRequestData = data.value?.data.hvc_request_data;
			isCampusEmailVerificationRequested.value =
				hvcRequestData.status === 'HVC_REQUESTED' &&
				hvcRequestData.communication_mode === 'EMAIL';
			isCampusDocVerificationRequested.value =
				hvcRequestData.status === 'HVC_REQUESTED' &&
				hvcRequestData.communication_mode === 'DOCUMENT';
		}
		persist();
	};

	const updateAuthTokens = (
		newAccessToken: string,
		newRefreshToken: string,
	) => {
		accessToken.value = newAccessToken;
		refreshToken.value = newRefreshToken;

		persist();
	};

	const handleUserAddress = async (body: any) => {
		await handleAddressMutation(body);
		await getUserInfo();
		return;
	};
	const handleDeleteAddress = (id: number | null) => {
		const body = {
			id: id,
		};
		handleDeleteAddressMutation(body, {
			async onSuccess() {
				await getUserInfo();
				if (
					Object.keys(defaultAddress.value || {}).length === 0 &&
					addresses.value.length !== 0
				) {
					const payload = {
						address: {
							firstName: addresses.value[0].firstName,
							lastName: addresses.value[0].lastName,
							phone:
								'+' +
								addresses.value[0].phonePrefix +
								addresses.value[0].phoneNumber,
							address1: addresses.value[0].address1,
							address2: addresses.value[0].address2,
							country: addresses.value[0].country,
							countryCode: addresses.value[0].countryCode,
							city: addresses.value[0].city,
							label: addresses.value[0].label,
							province: addresses.value[0].province,
							postalCode: addresses.value[0].zip,
							zip: addresses.value[0].zip,
							logan_address_id: addresses.value[0].logan_address_id,
							default: true,
						},
						accessToken: accessToken.value,
						logan_id: loganId.value,
						id: addresses.value[0].logan_address_id,
					};
					handleUserAddress(payload);
				}
			},
		});
	};

	// used when user tries to access a page that requires sign in
	const setRequiresSignIn = () => {
		requiresSignIn.value = true;
		showLoginSheet.value = true;
	};

	const setShowLoginSheet = (value: boolean) => {
		showLoginSheet.value = value;
	};

	const signOut = () => {
		loganId.value = null;
		email.value = null;
		firstName.value = null;
		lastName.value = null;
		phoneNumber.value = null;
		shopifyId.value = null;
		addresses.value = [];
		defaultAddress.value = null;
		tags.value = [];
		isHvc.value = false;
		accessToken.value = null;
		refreshToken.value = null;
		isSignedIn.value = false;
		gender.value = null;
		dateOfBirth.value = null;
		storeId.value = null;

		$eventClient.logout();
		persist();
	};
	return {
		loganId,
		email,
		firstName,
		lastName,
		phoneNumber,
		shopifyId,
		addresses,
		defaultAddress,
		tags,
		isHvc,
		accessToken,
		refreshToken,
		cartID,
		isSignedIn,

		loading,
		showOtpInputBox,
		invalidInput,
		invalidOtp,
		resendOtp,
		showAlert,
		alertMessage,
		existingAccount,
		showMergeSuccess,
		showMergeSheet,
		showLoginSheet,
		gender,
		dateOfBirth,
		storeId,
		isOldUser,
		gettingUserInfo,
		isCampusEmailVerificationRequested,
		isCampusDocVerificationRequested,
		showNewLogin,

		sendOtp,
		enterOtp,
		verifyEmail,
		verifyPhone,
		triggerInvalidInput,
		triggerInvalidOtp,
		triggerLoganAlert,
		sendMergerOtp,
		enterMergerOtp,
		deleteAccount,
		signOut,
		updateUserInfo,
		handleUserAddress,
		getUserInfo,
		handleDeleteAddress,
		setRequiresSignIn,
		setShowLoginSheet,
		updateAuthTokens,
		requiresSignIn,
	};
});
