import revive_payload_client_NssvtxYK8T from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_io_6f32b29410634cf54663294711b834e8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Euuubz66dn from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_io_6f32b29410634cf54663294711b834e8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_qIPBacdZtG from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_io_6f32b29410634cf54663294711b834e8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_NUYNRGiNCN from "/app/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.17.1_vite@5.2.10_@types+node@20.11.24_s_9f8491ff214c9abfad2e18fdc87293c1/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_FUgtMRWZT7 from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_io_6f32b29410634cf54663294711b834e8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_vVfbNFORuW from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_io_6f32b29410634cf54663294711b834e8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_Z8dpSEzNEG from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.17.1_typescript@5.6.3_vue@3.4.21_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/apps/online-store/.nuxt/components.plugin.mjs";
import prefetch_client_WqS6IXmApW from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_io_6f32b29410634cf54663294711b834e8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eSpoE55vfY from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.10.3_@parcel+watcher@2.4.1_@type_4630d1ac6402bbbe49a623a56301050e/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import titles_gQERtFvaco from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.1_@nuxt+devtools@2.2.1_vite@5.2.10_@types+nod_cf2f57f1608f8b97302b71f7766ceab2/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_NboEO74PcW from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.1_@nuxt+devtools@2.2.1_vite@5.2.10_@types+nod_cf2f57f1608f8b97302b71f7766ceab2/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_GN7MNVnXGL from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@2.2.1_vite@5.2.10_@types+node@20.11.24_s_4afb1524c596fd2d5f11e819f16461b3/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_JvOwf1EOeN from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@2.2.1_vite@5.2.10_@types+node@20.11.24_s_4afb1524c596fd2d5f11e819f16461b3/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import chunk_reload_client_lASYtMwSnl from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_io_6f32b29410634cf54663294711b834e8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_events_client_dLOKnLsuLH from "/app/apps/online-store/plugins/01.events.client.ts";
import _02_cathode_client_Qbf8UvtDFV from "/app/apps/online-store/plugins/02.cathode.client.ts";
import axios_QMFgzss1s4 from "/app/apps/online-store/plugins/axios.ts";
import disable_logs_PLbUQJb9qQ from "/app/apps/online-store/plugins/disable-logs.ts";
import error_handler_kEP5FliEXj from "/app/apps/online-store/plugins/error-handler.ts";
import sentry_client_shVUlIjFLk from "/app/apps/online-store/plugins/sentry.client.ts";
import vue_query_wrmMkNpEpe from "/app/apps/online-store/plugins/vue-query.ts";
export default [
  revive_payload_client_NssvtxYK8T,
  unhead_Euuubz66dn,
  router_qIPBacdZtG,
  _0_siteConfig_NUYNRGiNCN,
  payload_client_FUgtMRWZT7,
  check_outdated_build_client_vVfbNFORuW,
  plugin_vue3_Z8dpSEzNEG,
  components_plugin_KR1HBZs4kY,
  prefetch_client_WqS6IXmApW,
  plugin_eSpoE55vfY,
  titles_gQERtFvaco,
  defaults_NboEO74PcW,
  siteConfig_GN7MNVnXGL,
  inferSeoMetaPlugin_JvOwf1EOeN,
  chunk_reload_client_lASYtMwSnl,
  _01_events_client_dLOKnLsuLH,
  _02_cathode_client_Qbf8UvtDFV,
  axios_QMFgzss1s4,
  disable_logs_PLbUQJb9qQ,
  error_handler_kEP5FliEXj,
  sentry_client_shVUlIjFLk,
  vue_query_wrmMkNpEpe
]