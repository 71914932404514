export type MetaDataKeys = 'homepage' | 'women' | 'men' | 'kids';

export const STORE_TYPE = {
	OCTANE: 'OCTANE',
	MARKETPLACE: 'MARKETPLACE',
};
export const INVENTORY = {
	OOS: 'out-of-stock',
};
// prettier-ignore
export const META_TITLE = {
	LAAM: 'Pakistan\'s Largest Fashion Discovery Platform',
};

// prettier-ignore
export const META_DESC = {
	LAAM: 'LAAM.pk is Pakistan\'s one-stop fashion online store that is home to the country\'s finest designer wear. The main aim behind this venture is to host various Pakistani designer, prêt, lifestyle and luxury brands under one roof where global customers can shop what they want to under the banner of LAAM.',
};

// prettier-ignore
export const META_DATA: Record<
	MetaDataKeys,
	{ title: string; description: string }
> = {
	homepage: {
		title:
			'Shop Online at LAAM | Pakistan\'s Largest Fashion Discovery Platform',
		description:
			'Discover designer wear and top brands at LAAM.pk, Pakistan’s ultimate fashion destination. Shop clothing, accessories, footwear, and more with worldwide delivery and a seamless shopping experience',
	},
	women: {
		title: 'Women\'s Fashion at LAAM | Clothing, Footwear & Accessories',
		description:
			'Discover the latest in women’s fashion at LAAM.pk. Shop designer wear, eastern & western clothing, modest wear, accessories, footwear, and more, all with worldwide delivery and effortless shopping',
	},
	men: {
		title: 'Men\'s Collection at LAAM.pk | Clothing , Footwear & Accessories',
		description:
			'Explore the best in men’s fashion at LAAM.pk. Shop designer wear, eastern & western clothing, activewear, accessories, footwear, and more with worldwide delivery and easy shopping',
	},
	kids: {
		title: 'Kids\' Collection at LAAM.pk | Clothing, Footwear & Accessories',
		description:
			'Shop the latest styles for kids at LAAM.pk. Discover clothing, footwear, accessories, and more for boys and girls, with worldwide delivery and a seamless shopping experience',
	},
};
